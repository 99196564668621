<template>
  <div id="services" class="services mx-auto text-center px-4">
    <h1><b>Services</b></h1>
    <h3>Calligraphy</h3>
    <p>Lorem ipsum dolor sit amet, qui in dolorem ceteros phaedrum. Vix eius facer detracto ne,
    te accumsan adipiscing argumentum nam. Ei adhuc gubergren vix. Novum fabulas perfecto eu vis.
    An ceteros feugait scaevola sed, qui at sanctus accumsan aliquando. Pro an labitur comprehensam.
    Ex fierent necessitatibus pri. Te vitae delenit maluisset cum, altera voluptatibus ea eos. Ut hinc
    commune his, his laudem dissentiet ei. Ut pro utamur detracto expetenda.</p>
    <h3>Chalk Boards</h3>
    <p>Lorem ipsum dolor sit amet, qui in dolorem ceteros phaedrum. Vix eius facer detracto ne,
    te accumsan adipiscing argumentum nam. Ei adhuc gubergren vix. Novum fabulas perfecto eu vis.
    An ceteros feugait scaevola sed, qui at sanctus accumsan aliquando. Pro an labitur comprehensam.
    Ex fierent necessitatibus pri. Te vitae delenit maluisset cum, altera voluptatibus ea eos. Ut hinc
    commune his, his laudem dissentiet ei. Ut pro utamur detracto expetenda.</p>
    <h3>Invitations</h3>
    <p>Lorem ipsum dolor sit amet, qui in dolorem ceteros phaedrum. Vix eius facer detracto ne,
    te accumsan adipiscing argumentum nam. Ei adhuc gubergren vix. Novum fabulas perfecto eu vis.
    An ceteros feugait scaevola sed, qui at sanctus accumsan aliquando. Pro an labitur comprehensam.
    Ex fierent necessitatibus pri. Te vitae delenit maluisset cum, altera voluptatibus ea eos. Ut hinc
    commune his, his laudem dissentiet ei. Ut pro utamur detracto expetenda.</p>
    <h3>Custom</h3>
    <p>Lorem ipsum dolor sit amet, qui in dolorem ceteros phaedrum. Vix eius facer detracto ne,
    te accumsan adipiscing argumentum nam. Ei adhuc gubergren vix. Novum fabulas perfecto eu vis.
    An ceteros feugait scaevola sed, qui at sanctus accumsan aliquando. Pro an labitur comprehensam.
    Ex fierent necessitatibus pri. Te vitae delenit maluisset cum, altera voluptatibus ea eos. Ut hinc
    commune his, his laudem dissentiet ei. Ut pro utamur detracto expetenda.</p>
    <RequestAQuoteButton :outlined="true"/>
  </div>
</template>

<script>
// @ is an alias to /src
import RequestAQuoteButton from '@/components/RequestAQuoteButton'

export default {
  name: 'Services',
  components: { RequestAQuoteButton }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .services {
    max-width: 1920px;
    margin: 20px 0 20px 0;
  }
  .services > h3, p {
    text-align: left;
  }
  @media (max-width: 979px) {
    .services > h3 {
      text-align: center;
    }
  }
</style>
