<template>
  <div v-if="isMaintenanceMode" id="app">
    <MaintenancePage />
  </div>
  <div v-else id="app" :class="goDark ? 'dark-theme' : 'light-theme'">
    <Nav :darkMode="goDark" @toggleDarkMode="toggleDarkMode" />
    <main>
      <router-view/>
    </main>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Nav from '@/components/Navigation'
import Footer from '@/components/Footer'
import MaintenancePage from '@/components/MaintenancePage'

export default {
  components: { Nav, Footer, MaintenancePage },
  data () {
    return {
      isMaintenanceMode: false,
      goDark: false
    }
  },
  methods: {
    toggleDarkMode () {
      this.goDark = !this.goDark
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/global.scss';
@import './assets/scss/dark-theme.scss';
@import './assets/scss/light-theme.scss';
</style>
