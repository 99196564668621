<template>
  <mdb-btn class="custom-btn" :class="outlined ? 'custom-btn-outlined' : ''" @click="$router.push({ name: 'quote' })" rounded>Request A Quote</mdb-btn>
</template>

<script>
import { mdbBtn } from 'mdbvue'
export default {
  name: 'RequestAQuoteButton',
  props: {
    outlined: {
      type: Boolean,
      default: false
    }
  },
  components: { mdbBtn }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
