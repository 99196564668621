import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import RequestAQuote from '../views/RequestAQuote'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/quote',
    name: 'quote',
    component: RequestAQuote
  },
  {
    path: '*',
    component: Home,
    beforeEnter: (to, from, next) => {
      next('/home')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: function (to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash }
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
