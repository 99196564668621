<template>
  <div class="raq mx-auto px-3">
    <p v-if="success">Email sent! <router-link to="/">Go back.</router-link></p>
    <RequestAQuoteForm :isLoading="isLoading" @formSubmitted="submit" />
  </div>
</template>

<script>
// @ is an alias to /src
import RequestAQuoteForm from '@/components/RequestAQuoteForm'
import axios from 'axios'

export default {
  name: 'RequestAQuote',
  components: { RequestAQuoteForm },
  data () {
    return {
      isLoading: false,
      success: false
    }
  },
  methods: {
    async submit (payload) {
      this.isLoading = true
      const baseURL = 'https://ccht1ettsg.execute-api.us-east-1.amazonaws.com/fancyflowdesigns-request-a-quote-form'
      await axios.post(baseURL, payload)
        .then(response => {
          console.log(response)
          this.success = true
        })
        .catch(error => {
          console.log(error)
        })
      this.isLoading = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.raq {
  margin-top: 16px;
  max-width: 1200px;
}
</style>
