<template>
  <div>
    <mdb-navbar class="navigation-bar" position="top" :toggler="false">
      <mdb-navbar-nav left>
        <mdb-icon
          icon="bars"
          size="2x"
          @click.native="show = !show"
        />
      </mdb-navbar-nav>
      <mdb-navbar-nav class="nav-flex-icons" center>
        <mdb-nav-item to="{ name: 'home' }">
          <div class="logo logo-width" v-html="svgLogo"></div>
        </mdb-nav-item>
      </mdb-navbar-nav>
      <mdb-navbar-nav class="nav-flex-icons">
        <mdb-nav-item>
          <div class="toggle custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="darkSwitch" v-model="switchValue" @click="toggleDarkMode">
            <label class="custom-control-label" for="darkSwitch">Dark Mode</label>
          </div>
        </mdb-nav-item>
      </mdb-navbar-nav>
    </mdb-navbar>

    <mdb-side-nav-2
      sidenav-class="side-navigation"
      v-model="show"
      over
      :data="navigation"
    >
      <div class="sidenav-header" slot="header">
        <div class="logo" v-html="svgLogo"></div>
      </div>
    </mdb-side-nav-2>
  </div>
</template>

<script>
import axios from 'axios'
import { mdbNavbar, mdbNavItem, mdbNavbarNav, mdbSideNav2, mdbIcon } from 'mdbvue'

export default {
  name: 'Nav',
  components: {
    mdbNavbar,
    mdbNavItem,
    mdbNavbarNav,
    mdbSideNav2,
    mdbIcon
  },
  props: {
    darkMode: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.loadSvgLogo()
  },
  data () {
    return {
      svgLogo: '',
      show: false,
      switchValue: false,
      navigation: [
        {
          name: 'Home',
          icon: 'home',
          children: [
            {
              name: 'Image Carousel',
              icon: 'picture',
              to: '/home#images'
            },
            {
              name: 'Services',
              icon: 'concierge-bell',
              to: '/home#services'
            },
            {
              name: 'Pricing',
              icon: 'money-bill',
              to: '/home#pricing'
            }
          ]
        },
        {
          name: 'Request A Quote',
          icon: 'quote-left',
          to: 'quote'
        }
      ]
    }
  },
  methods: {
    toggleSideNav () {
      console.log('toggle sidenav')
      this.show = !this.show
    },
    loadSvgLogo () {
      axios({
        method: 'get',
        url: '../img/default-monochrome-black.svg'
      })
        .then(result => { this.svgLogo = result.data })
        .catch(error => console.log(error))
    },
    toggleDarkMode () {
      this.$emit('toggleDarkMode', this.switchValue)
    }
  },
  watch: {
    $route () {
      this.show = false
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .sidenav-header {
    margin-bottom: 10px;
  }
  .logo {
    width: 100% \9;
  }
  .logo-width {
    min-width: 250px;
  }
  .navbar i {
    cursor: pointer;
  }
  .toggle {
    white-space: nowrap;
  }
</style>
<style lang="scss">
</style>
