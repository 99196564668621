<template>
  <form @submit.prevent="onSubmit">
    <h2 class="text-center mb-3">Request A Quote</h2>
    <div class="text-color text-left">
      <span>Select service(s):</span>
      <div class="form-inline">
        <mdb-input
          class="checkbox"
          v-for="service in payload.services"
          type="checkbox"
          v-model="service.checked"
          :id="service.label"
          :key="service.id"
          :label="service.name"
        />
      </div>
      <mdb-input
        class="inputfield"
        id="name-input"
        v-model="payload.name"
        label="Your name"
        icon="user"
        type="text"
        required
      />
      <mdb-input
        class="inputfield"
        id="email-input"
        v-model="payload.email"
        label="Your email"
        icon="envelope"
        type="email"
        required
      />
      <mdb-input
        class="inputfield"
        id="phone-input"
        v-model="payload.phone"
        label="Your phone number"
        icon="phone"
        type="text"
        required
      />
      <mdb-textarea
        class="inputfield"
        id="details-input"
        v-model="payload.details"
        :rows="3"
        label="Additional details"
        icon="pencil"
      />
    </div>
    <div class="text-center">
      <mdb-btn class="custom-btn" data-testid="send-btn" type="submit" rounded>
        Send <mdb-icon v-if="!isLoading" far icon="paper-plane" class="ml-1 icon-color"/>
        <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        <span class="sr-only">Loading...</span>
      </mdb-btn>
    </div>
  </form>
</template>

<script>
// @ is an alias to /src
import { mdbIcon, mdbInput, mdbBtn, mdbTextarea } from 'mdbvue'
export default {
  name: 'RequestAQuoteForm',
  components: {
    mdbIcon,
    mdbInput,
    mdbBtn,
    mdbTextarea
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      payload: {
        services: [
          { id: 0, name: 'Calligraphy', label: 'calligraphy-checkbox', checked: false },
          { id: 1, name: 'Chalkboard', label: 'chalkboard-checkbox', checked: false },
          { id: 2, name: 'Invitations', label: 'invitations-checkbox', checked: false },
          { id: 3, name: 'Custom', label: 'custom-checkbox', checked: false }
        ],
        name: '',
        email: '',
        phone: '',
        details: ''
      }
    }
  },
  methods: {
    onSubmit () {
      this.$emit('formSubmitted', this.payload)
      this.resetForm()
    },
    resetForm () {
      this.payload = {
        services: [
          { id: 0, name: 'Calligraphy', label: 'calligraphy-checkbox', checked: false },
          { id: 1, name: 'Chalkboard', label: 'chalkboard-checkbox', checked: false },
          { id: 2, name: 'Invitations', label: 'invitations-checkbox', checked: false },
          { id: 3, name: 'Custom', label: 'custom-checkbox', checked: false }
        ],
        name: '',
        email: '',
        phone: '',
        details: ''
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.form-inline {
  justify-content: center;
}
.text-color {
  color: #757575;
}
</style>

<style lang="scss">
.icon-color {
  color: black !important;
}
</style>
