<template>
  <div id="pricing" class="pricing">
    <div class="pricing-table mx-auto">
      <h1><b>Pricing</b></h1>
      <mdb-datatable-2 v-model="data" bordered striped :pagination="false" />
      <RequestAQuoteButton />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import RequestAQuoteButton from '@/components/RequestAQuoteButton'
import { mdbDatatable2 } from 'mdbvue'

export default {
  name: 'Pricing',
  components: { mdbDatatable2, RequestAQuoteButton },
  data () {
    return {
      data: {
        columns: [
          {
            label: 'Name',
            field: 'name'
          },
          {
            label: 'Description',
            field: 'description'
          },
          {
            label: 'Price',
            field: 'price',
            sort: true,
            format: value => '$' + value
          }
        ],
        rows: [
          {
            name: 'Calligraphy',
            description: 'I do some calligraphy for you.',
            price: ''
          },
          {
            name: 'Chalkboard',
            description: 'I draw on a chalkboard for you.',
            price: '$'
          },
          {
            name: 'Invitations',
            description: 'I make your Invitations for you.',
            price: '$$'
          },
          {
            name: 'Custom',
            description: 'I do custom shit for you.',
            price: '$$$'
          }
        ]
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  margin-bottom: 20px;
}
.pricing {
  padding: 20px 0 20px 0;
}
.pricing-table {
  max-width: 1920px;
}
</style>
