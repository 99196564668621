<template>
  <div class="view">
    <div class="mask flex-center rgba-black-strong">
      <div>
        <h2 class="white-text">Working on the site. Check back soon...</h2>
        <p>Contact <a href="mailto:fancyflowdesigns@gmail.com">fancyflowdesigns@gmail.com</a> for all inquiries or requests.</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MaintenancePage',
  components: { },
  data () {
    return {
      data: {
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
  body {
    background: url('~@/assets/images/maintenance_bg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  .view {
    color: white;
    position: initial;
  }
</style>
