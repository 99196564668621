<template>
  <div class="home">
    <ImageCarousel />
    <Services />
    <Pricing />
  </div>
</template>

<script>
// @ is an alias to /src
import ImageCarousel from '@/components/ImageCarousel'
import Services from '@/components/Services'
import Pricing from '@/components/Pricing'

export default {
  name: 'Home',
  components: {
    ImageCarousel,
    Services,
    Pricing
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
